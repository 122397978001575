import { createBrowserRouter } from 'react-router-dom'
import { redirect } from 'react-router-dom'
import i18n from '../i18n'

const router = createBrowserRouter([
	{
		path: '/',
		loader: async () => redirect('/en'),
	},
	{
		path: '/:language',
		loader: async ({ request, params }) => {
			if (i18n.language !== params.language) {
				i18n.changeLanguage(params.language)
			}

			return { request, params }
		},
		lazy: async () => {
			const Home = (await import('../pages/Home')).default

			return { Component: Home }
		},
	},
])

export default router
